import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-regular-svg-icons';

import Layout from '../components/layout';

const Resources = () => {
  var pageTitle = 'Resources';

  return (
    <Layout slug="resources" title={pageTitle}>
      <h1>{pageTitle}</h1>

      <h2>Waivers</h2>
      <ul>
        <li>
          <a href="/VIP_Dog_Boarding_Waiver.pdf" target="_blank">
            <FontAwesomeIcon icon={faFilePdf} className="mr-3" />
            VIP_Dog_Boarding_Waiver.pdf
          </a>
        </li>
        <li>
          <a href="/VIP_Cat_Boarding_Waiver.pdf" target="_blank">
            <FontAwesomeIcon icon={faFilePdf} className="mr-3" />
            VIP_Cat_Boarding_Waiver.pdf
          </a>
        </li>
      </ul>
    </Layout>
  );
};

export default Resources;
